.zero-proof-container {
    position: relative;
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    justify-content: center;
    align-items: center;
    background-color: #121212;
    color: #ffffff;
  }

  /* Header styles */
  .zero-proof-glass-header {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    backdrop-filter: blur(10px);
    background-color: rgba(18, 18, 18, 0.7);
    padding: 10px 0;
    z-index: 2;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }

  .zero-proof-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    width: 90vw;
    position: relative;
  }

  .zero-proof-logo-container {
    display: flex;
    align-items: center;
    gap: 15px;
    flex: 1;
  }

  .zero-proof-logo-image {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }

  .zero-proof-header-title {
    font-size: 1.8em;
    font-weight: bold;
    margin: 0;
    white-space: nowrap;
  }

  /* Burger Menu styles */
  .zero-proof-burger {
    position: relative;
    width: 40px;
    height: 30px;
    background: transparent;
    cursor: pointer;
    display: block;
  }

  .zero-proof-burger input {
    display: none;
  }

  .zero-proof-burger span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  .zero-proof-burger span:nth-of-type(1) {
    top: 0px;
    transform-origin: left center;
  }

  .zero-proof-burger span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: left center;
  }

  .zero-proof-burger span:nth-of-type(3) {
    top: 100%;
    transform-origin: left center;
    transform: translateY(-100%);
  }

  .zero-proof-burger input:checked ~ span:nth-of-type(1) {
    transform: rotate(45deg);
    top: 0px;
    left: 5px;
  }

  .zero-proof-burger input:checked ~ span:nth-of-type(2) {
    width: 0%;
    opacity: 0;
  }

  .zero-proof-burger input:checked ~ span:nth-of-type(3) {
    transform: rotate(-45deg);
    top: 28px;
    left: 5px;
  }

  /* Dropdown Menu styles */
  .zero-proof-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: rgba(30, 30, 30, 0.95);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    border-radius: 0 0 4px 4px;
    padding: 10px 0;
    min-width: 200px;
    color: #ffffff;
  }

  .zero-proof-menu-item {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .zero-proof-menu-item:hover {
    background-color: rgba(72, 202, 228, 0.15);
    color: #48cae4;
  }

  .zero-proof-header-controls {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .zero-proof-qr-button {
    display: none; /* Hidden by default on mobile */
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #48cae4;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }

  .zero-proof-qr-button:hover {
    background-color: #36b9d3;
  }

  @media (min-width: 768px) {
    .zero-proof-qr-button {
      display: flex; /* Show on desktop */
    }
  }

  /* Modal styles */
  .zero-proof-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .zero-proof-modal-content {
    background-color: white;
    padding: 24px;
    border-radius: 12px;
    position: relative;
    max-width: 90%;
    width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .zero-proof-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #666;
    transition: background-color 0.2s;
  }

  .zero-proof-modal-close:hover {
    background-color: #f0f0f0;
  }

  .zero-proof-qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
  }

  .zero-proof-qr-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
  }

  .zero-proof-qr-code {
    width: 400px;
    height: 400px;
    object-fit: contain;
  }

  .zero-proof-qr-description {
    color: #666;
    margin: 0;
  }

  /* Hero section styles */
  .zero-proof-hero-container {
    padding: 24px;
    max-width: 1400px;
  }

  .zero-proof-hero-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
  }

  .zero-proof-hero-text-content {
    width: 100%;
    justify-content: center;
  }

  .zero-proof-hero-title {
    font-size: 3rem;
    font-weight: bold;
    text-align: left;
  }

  .zero-proof-hero-description {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #dddddd;
  }

  .zero-proof-hero-image-container {
    width: 100%;
  }

  .zero-proof-hero-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  /* Applies when the screen width is 768px or larger */
  @media (min-width: 768px) {
    .zero-proof-hero-wrapper {
      flex-direction: row;
      align-items: center;
    }

    .zero-proof-hero-text-content {
      width: 50%;
    }

    .zero-proof-hero-image-container {
      width: 50%;
    }

    .zero-proof-hero-title {
      font-size: 3rem;
    }
  }

  /* Privacy Section */
  .zero-proof-privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 24px;
    line-height: 1.6;
    font-family: Arial, sans-serif;
    color: #ffffff;
  }

  .zero-proof-privacy-policy-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
    color: #ffffff;
  }

  .zero-proof-privacy-policy-update-date {
    font-size: 0.9rem;
    text-align: center;
    color: #bbbbbb;
    margin-bottom: 32px;
  }

  .zero-proof-privacy-section {
    margin-bottom: 24px;
  }

  .zero-proof-privacy-subtitle {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 8px;
    color: #48cae4;
  }

  .zero-proof-privacy-text {
    font-size: 1rem;
    color: #dddddd;
    margin-bottom: 16px;
  }

  .zero-proof-privacy-contact-link {
    color: #48cae4;
    text-decoration: none;
  }

  .zero-proof-privacy-contact-link:hover {
    text-decoration: underline;
  }

  /* Features Section */
  .zero-proof-features-section {
    margin: 48px auto;
    max-width: 1200px;
    padding: 0;
    text-align: center;
  }

  .zero-proof-features-title {
    font-size: 2rem;
    margin-bottom: 24px;
  }

  .zero-proof-features-cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    padding: 16px;
  }

  .zero-proof-feature-card {
    background: #1e1e1e;
    border: 1px solid #333333;
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    color: #ffffff;
    text-align: center;
  }

  .zero-proof-feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    border-color: #48cae4;
  }

  .zero-proof-feature-icon {
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
  }

  .zero-proof-feature-card h4 {
    font-size: 1.5rem;
    margin-bottom: 8px;
    color: #48cae4;
  }

  .zero-proof-feature-card p {
    font-size: 1rem;
    color: #bbbbbb;
    text-align: center;
  }

  @media (min-width: 768px) {
    .zero-proof-features-cards {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, auto);
    }
  }

  /* Footer Section */
  .zero-proof-footer {
    color: #fff;
    width: 100vw;
    justify-content: center;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }

  .zero-proof-footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;

  }

  .zero-proof-footer-section h4 {
    font-size: 1.25rem;
    margin-bottom: 16px;
  }

  .zero-proof-footer-section p {
    font-size: 0.875rem;
    color: #ccc;
    margin-bottom: 8px;
  }

  .zero-proof-social-icons img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    transition: opacity 0.3s ease;
  }

  .zero-proof-social-icons img:hover {
    opacity: 0.7;
  }

  .zero-proof-footer-credits {
    text-align: center;
    margin-top: 24px;
    font-size: 0.75rem;
    color: #999;
  }

  /* Accent color utility classes */
  .zero-proof-accent-color {
    color: #48cae4;
  }

  .zero-proof-accent-bg {
    background-color: #48cae4;
  }

  .zero-proof-accent-border {
    border-color: #48cae4;
  }