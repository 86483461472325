.container {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

/* Header styles */
.glass-header {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px 0;
  z-index: 2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 90vw;
  position: relative;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 15px;
  flex: 1;
}

.logo-image {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}

.header-title {
  font-size: 1.8em;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
}

/* Burger Menu styles */
.burger {
  position: relative;
  width: 40px;
  height: 30px;
  background: transparent;
  cursor: pointer;
  display: block;
}

.burger input {
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: black;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.burger span:nth-of-type(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  transform-origin: left center;
  transform: translateY(-100%);
}

.burger input:checked ~ span:nth-of-type(1) {
  transform: rotate(45deg);
  top: 0px;
  left: 5px;
}

.burger input:checked ~ span:nth-of-type(2) {
  width: 0%;
  opacity: 0;
}

.burger input:checked ~ span:nth-of-type(3) {
  transform: rotate(-45deg);
  top: 28px;
  left: 5px;
}

/* Dropdown Menu styles */
.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 4px 4px;
  padding: 10px 0;
  min-width: 200px;
}

.menu-item {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.menu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.header-controls {
  display: flex;
  align-items: center;
  gap: 20px;
}

.qr-button {
  display: none; /* Hidden by default on mobile */
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.qr-button:hover {
  background-color: #333;
}

@media (min-width: 768px) {
  .qr-button {
    display: flex; /* Show on desktop */
  }
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  position: relative;
  max-width: 90%;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #666;
  transition: background-color 0.2s;
}

.modal-close:hover {
  background-color: #f0f0f0;
}

.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
}

.qr-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.qr-code {
  width: 400px;
  height: 400px;
  object-fit: contain;
}

.qr-description {
  color: #666;
  margin: 0;
}

/* Hero section styles */
.hero-container {
  padding: 24px;
  max-width: 1400px;
}

.hero-wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 24px;
}

.hero-text-content {
  width: 100%;
  justify-content: center;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  text-align: left;
}

.hero-description {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #4a4a4a;
}

.hero-image-container {
  width: 100%;
}

.hero-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Applies when the screen width is 768px or larger */
@media (min-width: 768px) {
  .hero-wrapper {
    flex-direction: row;
    align-items: center;
  }

  .hero-text-content {
    width: 50%;
  }

  .hero-image-container {
    width: 50%;
  }

  .hero-title {
    font-size: 3rem;
  }
}



/* Privacy Section */
.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
  line-height: 1.6;
  font-family: Arial, sans-serif;
  color: #333;
}

.privacy-policy-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
}

.privacy-policy-update-date {
  font-size: 0.9rem;
  text-align: center;
  color: #777;
  margin-bottom: 32px;
}

.privacy-section {
  margin-bottom: 24px;
}

.privacy-subtitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.privacy-text {
  font-size: 1rem;
  color: #555;
  margin-bottom: 16px;
}

.privacy-contact-link {
  color: #007bff;
  text-decoration: none;
}

.privacy-contact-link:hover {
  text-decoration: underline;
}

/* Features Section */
.features-section {
  margin: 48px auto;
  max-width: 1200px;
  padding: 0;
  text-align: center;
}

.features-title {
  font-size: 2rem;
  margin-bottom: 24px;
}

.features-cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  padding: 16px;
}

.feature-card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.feature-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 16px;
}

.feature-card h4 {
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.feature-card p {
  font-size: 1rem;
  color: #666;
}

@media (min-width: 768px) {
  .features-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Footer Section */
.footer {
  color: #fff;
  width: 100vw;
  justify-content: center;
  margin-bottom: 5vh;
  margin-top: 5vh;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;

}

.footer-section h4 {
  font-size: 1.25rem;
  margin-bottom: 16px;
}

.footer-section p {
  font-size: 0.875rem;
  color: #ccc;
  margin-bottom: 8px;
}

.social-icons img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  transition: opacity 0.3s ease;
}

.social-icons img:hover {
  opacity: 0.7;
}

.footer-credits {
  text-align: center;
  margin-top: 24px;
  font-size: 0.75rem;
  color: #888;
}
